/* Navbar */
.navbar {
  /* border-bottom: 1px solid #e3e3e3; */
  box-shadow: none;
}
.navbar.navbar-transparent {
  background-color: #fff !important;
  box-shadow: none;
  color: #ffffff;
  padding-top: 20px !important;
}
.page-nav.navbar.navbar-transparent {
  background-color: #4a4d5170 !important;
}
.wrapper {
  margin-top: 95px;
}

.bootstrap-switch.wrapper {
  margin-top: 6px;
}
.bg-info,
.cmpBtn {
  background-color: #2680c2 !important;
  font-size: 18px;
  font-weight: 700;
  border: none;
}
.bg-info:hover,
.cmpBtn:hover {
  background-color: #002147 !important;
}

.cmpBtnlong {
  padding: 14px 132px;
}
.login h3 {
  color: #002147;
}

.login label {
  font-size: 18px;
  color: #002147;
}
.login .msg {
  font-size: 18px;
  color: #51586c;
}

.login .btn-info {
  background-color: #2680c2;
  font-size: 18px;
}

/* registration */

.registration h3 {
  color: #002147;
}

.registration label {
  font-size: 18px;
  color: #002147;
}
.registration .msg {
  font-size: 18px;
  color: #51586c;
}

.registration .btn-info {
  background-color: #2680c2;
  font-size: 18px;
}

/* hero */
.page-header .content-center-hero {
  position: absolute;
  top: 22%;
  z-index: 2;
  color: #ffffff;
  width: 100%;
  /* max-width: 880px; */
}

h2.hero-title span {
  font-size: 100px;
  font-weight: 900;
}
h2.hero-title .serComm {
  font-size: 48px;
}
.lineBrk {
  display: block;
}
.hero-paragraph {
  font-size: 20px;
}
.aboutContent {
  margin-top: 5rem;
}
.supportProject {
  font-size: 24px;
}
.hero-color-overlay {
  background: linear-gradient(89deg, #ffffff73, transparent);
  /* padding-left: 22%; */
  padding-bottom: 5%;
}

.btn-outline-info:hover {
  text-decoration: auto;
}

/* startcampaign */
h3 {
  margin-bottom: 10px;
  line-height: 30px;
}
.campaign .parag,
label {
  font-size: 17px;
  font-weight: 400;
  color: #4f566a;
}
.campaign .bold {
  font-size: 20px;
  font-weight: 400;
  color: #002147;
}
.form-check-radio input[type="radio"]:checked + .form-check-sign::after {
  width: 12px;
  height: 12px;
  background-color: #2ca8ff;
  border-color: #2ca8ff;
  top: 7px;
  left: 7px;
}

.form-check-radio .form-check-sign::before,
.form-check-radio .form-check-sign::after {
  border-radius: 0;
  border: 1px solid #2ca8ff;
}

.campaign,
option {
  font-size: 20px;
  font-weight: 400;
  color: #51586c;
}
.campaign .input-group .form-control:first-child,
.input-group-text:first-child,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
  border: 1px !important;
}
.campaign .camp-alert {
  font-size: 24px;
  font-weight: 400;
  color: #2680c2;
}

/* section-acheived */

.section-acheived h3.ach-title {
  font-size: 16px;
  padding: 0;
  line-height: 0;
}

/* section donate */
.page-header {
  background: rgb(55 40 40 / 46%);
}
.page-header.donate {
  /*min-height: 60vh !important;*/
  max-height: 400 px;
  padding: 0;
  position: relative;
  overflow: hidden;
}

/* campaign */

.title-camp {
  font-size: 40px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  background: linear-gradient(88deg, #007bff, transparent);
  padding: 30px;
}
.section-campaign {
  margin-top: 5rem;
}
.section .single-campaign {
  background-color: #f4f4f4;
  padding: 60px 30px;
  border-bottom: 3px solid #2ca8ff;
}
.section-campaign .campaign-header {
  background-color: #f4f4f4;
  padding: 30px;
  margin: 30px 0;
}

.section-campaign .campaign-header h3 {
  margin: 0;
}

/* carousel slides */

.carousel-campaign .campaign-carousel-caption {
  position: absolute;
  bottom: 45%;
  padding-top: 20px;
  padding: 20px;
  color: #000;
}
.carousel-campaign h3 {
  margin-bottom: 0;
}
.campaign-meta {
  padding: 20px;
}

.carousel-campaign .now-ui-icons {
  background: #a09090;
  padding: 20px;
  border-radius: 50%;
}

.carousel-campaign .muted {
  color: #a3acb8;
  font-size: 20px;
  font-weight: 400;
}
.carousel-campaign .carousel .carousel-inner {
  box-shadow: 0px 10px 25px 0px rgb(255 255 255 / 0%);
  border: 1px solid grey;
  border-radius: 10px;
}

.carousel-campaign .carousel-control-prev {
  left: -15%;
}
.carousel-campaign .carousel-control-next {
  right: -15%;
}
.form-control:focus {
  border: 1px solid #2680c2;
}
/* Work */
/* campaign */

.title-camp {
  font-size: 40px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  background: linear-gradient(88deg, #007bff, transparent);
  padding: 30px;
}
.section-work {
  margin-top: 1rem;
}
.section .single-campaign {
  background-color: #f4f4f4;
  padding: 60px 30px;
  border-bottom: 3px solid #2ca8ff;
}
.section-work .campaign-header {
  background-color: #f4f4f4;
  padding: 30px;
  margin: 30px 0;
}

.section-work .campaign-header h3 {
  margin: 0;
}

/* sidebar */

.bd-sidebar {
  order: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.bd-links {
  display: block !important;
}

.bd-search {
  position: relative;
  padding: 1rem 15px;
  margin-right: -15px;
  margin-left: -15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.bd-search-docs-toggle {
  line-height: 1;
  color: #212529;
}

.bd-sidenav {
  display: none;
}

.bd-toc-link {
  display: block;
  padding: 0.25rem 1.5rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.65);
}
.bd-toc-link:hover {
  color: rgba(0, 0, 0, 0.85);
  text-decoration: none;
}

.bd-toc-item.active {
  margin-bottom: 1rem;
}
.bd-toc-item.active:not(:first-child) {
  margin-top: 1rem;
}
.bd-toc-item.active > .bd-toc-link {
  color: #002147;
  font-size: 24px;
  font-weight: bold;
}
.bd-toc-item.active > .bd-toc-link:hover {
  background-color: transparent;
}
.bd-toc-item.active > .bd-sidenav {
  display: block;
}

.bd-sidebar .nav > li > a {
  display: block;
  padding: 0.25rem 1.5rem;
  font-size: 90%;
  color: rgba(0, 0, 0, 0.65);
}

.bd-sidebar .nav > li > a:hover {
  color: rgba(0, 0, 0, 0.85);
  text-decoration: none;
  background-color: transparent;
}

.bd-sidebar .nav > .active > a,
.bd-sidebar .nav > .active:hover > a {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  background-color: transparent;
}

/* content */

.bd-content {
  order: 1;
}
.bd-content > h2[id],
.bd-content > h3[id],
.bd-content > h4[id] {
  pointer-events: none;
}
.bd-content > h2[id] > div,
.bd-content > h2[id] > a,
.bd-content > h3[id] > div,
.bd-content > h3[id] > a,
.bd-content > h4[id] > div,
.bd-content > h4[id] > a {
  pointer-events: auto;
}
.bd-content > h2[id]::before,
.bd-content > h3[id]::before,
.bd-content > h4[id]::before {
  display: block;
  height: 6rem;
  margin-top: -6rem;
  visibility: hidden;
  content: "";
}
.bd-content > table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
}

.bd-content > table > thead > tr > th,
.bd-content > table > thead > tr > td,
.bd-content > table > tbody > tr > th,
.bd-content > table > tbody > tr > td,
.bd-content > table > tfoot > tr > th,
.bd-content > table > tfoot > tr > td {
  padding: 0.75rem;
  vertical-align: top;
  border: 1px solid rgba(0, 0, 0, 0.06);
}
.bd-content > table > thead > tr > th > p:last-child,
.bd-content > table > thead > tr > td > p:last-child,
.bd-content > table > tbody > tr > th > p:last-child,
.bd-content > table > tbody > tr > td > p:last-child,
.bd-content > table > tfoot > tr > th > p:last-child,
.bd-content > table > tfoot > tr > td > p:last-child {
  margin-bottom: 0;
}
.bd-content > table td:first-child > code {
  white-space: nowrap;
}

.bd-content > h2:not(:first-child) {
  margin-top: 3rem;
}

.bd-content > h3 {
  margin-top: 1.5rem;
}

.bd-content > ul li,
.bd-content > ol li {
  margin-bottom: 0.25rem;
}

/* Input */
.input-group .form-control:first-child,
.input-group-text:first-child,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
  border-right: 1px solid #e3e3e3;
}
/* Breadcrumb */

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 1rem;
  list-style: none;
  background-color: rgba(255, 0, 0, 0);
  border-radius: 0;
}
.breadcrumb a,
.breadcrumb-item.active {
  color: #4f566a;
  font-size: 24px;
}

/* create campaign */
.bd-title {
  font-size: 40px;
  font-weight: bold;
  color: #2680c2;
}
.create-campaign .campTitle {
  color: #002147 !important;
  font-size: 24px;
}

/* story */
.camp-alert,
.camp-alert h3 {
  color: #a3acb8;
}
.camp-story-title {
  font-size: 32px;
}

.camp-story-description {
  font-size: 24px;
}

.camp-alert ul {
  list-style-type: none;
  padding: 0;
}
/* Responsive  */

/* @media screen and (max-width: 400px) {
  .page-header .content-center {
    top: 55%;
    padding: 50px 0;
  }
} */

/* btn check */

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  border-radius: 5px;
}

.btn-check:active + .btn-outline-info,
.btn-check:checked + .btn-outline-info,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show,
.btn-outline-info:active {
  color: #fff;
  background-color: #2680c2;
  border-color: #0d6efd;
}
.donateBtn:hover {
  background-color: #002147 !important;
}
.donateBtn:hover {
  background-color: #2680c2 !important;
}
.donateTitle {
  font-size: 20px;
  color: #2ca8ff;
  font-weight: 500;
  margin-top: 20px;
}
.text-danger {
  color: #2680c2 !important;
}
.icon-danger {
  color: #002147;
}
p.donate-description {
  font-size: 16px;
}
.missionSub {
  font-weight: 500;
  margin-bottom: 30px;
}
.team-player {
  padding: 25px 8px 4px 8px;
}
.team-player h4,
.ourAchie h4 {
  font-size: 18px;
  font-weight: 500;
}
.team-player img {
  max-width: 170px !important;
  height: 170px;
  border-radius: 50%;
}
.team-player img {
}
.team-player:hover,
.ourAchie:hover {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 8%), 0 6px 20px 0 rgb(0 0 0 / 8%);
  cursor: pointer;
  border-radius: 12px;
  color: #2680c2;
}
.team-player:hover h4,
.ourAchie:hover h4 {
  font-weight: 600;
}
.ourAchie {
  padding: 51px 8px 4px 8px;
}
.heroColor {
  /* padding-left: 22%; */
  padding-bottom: 5%;
}
/* Works */
.section-works .btn-info {
  background-color: #2680c2;
}

.section-work p {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;

  -webkit-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 20px;
}

.center-img {
  display: block;
  width: 80%;
  margin: 0 auto;
}
.side-by-side-p {
  float: left;
}
.workParagraph {
  font-size: 17px;
  font-weight: 400;
}

.work_recent {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
}
.work_section {
  padding: 20px;
}

/* Volunteer */

.volunteer input[type="text"],
input[type="email"] {
  height: 50px;
  margin: 8px 0 30px;
  border: 0;
  border-radius: 8px;
}
.volunteer p {
  margin: 0;
}
.volunteer .btn {
  padding: 15px 0;
  font-size: 15px;
  font-weight: 600;
}
/* Donation form */
.form-control {
  font-size: 14px;
}
.donationForm input[type="text"],
input[type="email"],
select {
  height: 50px;
  margin: 8px 0 30px;
  border: 1px solid #243c4c36;
  border-radius: 8px;
}
.donationForm select {
  height: 50px;
  margin: 8px 0 30px;
  border: 0;
  border-radius: 8px;
}
.donationForm .donateTitle {
  margin-bottom: 20px;
}

/* Video */
.progMeta {
  font-size: 15px;
  display: inline-flex;
}
.progMeta .meta {
  margin-right: 20px;
}
.progMeta .meta span {
  font-weight: 600;
}
/* Modal */

.projModal,
.packModal {
  max-height: 600px;
  min-height: 600px;
}

/* cookie */

.cookieContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
}

.cookieContainer .btn {
  padding: 10px 40px;
}
.cookieText {
  padding-right: 8rem;
}

.img-raised {
  box-shadow: none;
}
@media screen and (max-width: 991px) {
  .sidebar-collapse .navbar .dropdown.show .dropdown-menu,
  .sidebar-collapse .navbar .dropdown .dropdown-menu {
    height: auto;
  }
}

@media (min-width: 992px) {
  .bd-content > ul,
  .bd-content > ol,
  .bd-content > p {
    max-width: 90%;
  }
}

@media only screen and (max-width: 768px) {
  .heroColor {
    text-align: center !important;
  }
  h2.hero-title {
    margin-left: 16px !important;
    text-align: center !important;
  }
  h2.hero-title span {
    font-size: 40px;
  }
  .page-header p {
    font-size: 18px;
  }
  .navbar .navbar-toggler {
    margin-right: 16px;
  }
}
@media (min-width: 768px) {
  /* single work header */
  .page-header .content-center {
    top: 60%;
  }
  .bd-sidebar {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
  @supports (position: sticky) {
    .bd-sidebar {
      position: sticky;
      top: 4rem;
      z-index: 1000;
      max-height: calc(100vh - 4rem);
    }
  }
}
@media (min-width: 1200px) {
  .bd-sidebar {
    max-width: 320px;
  }
}

.bd-links {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-right: -15px;
  margin-left: -15px;
}
@media (min-width: 768px) {
  @supports (position: sticky) {
    .bd-links {
      max-height: calc(100vh - 4rem);
      overflow-y: auto;
    }
  }
}

@media screen and (min-width: 991px) {
  .bd-content > table {
    display: block;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .hero-title {
    /* margin: 64px 0 !important; */
  }
}

@media screen and (max-width: 400px) {
  .section {
    padding: 20px 13px !important;
  }
  .page-header .content-center-hero {
    position: relative;
    top: 0%;
    margin: 55% 0 40%;
  }
  h2.hero-title .serComm {
    font-size: 24px;
  }
  h2.hero-title {
    margin-left: 2%;
    font-size: 25px;
  }
  h2.hero-title span {
    font-size: 48px;
    font-weight: 900;
  }
  p.hero-paragraph {
    font-size: 16px !important;
  }
  .aboutContent {
    margin-top: 2rem;
  }
  .hero-color-overlay {
    padding-bottom: 16%;
  }
  /* single work header */
  .page-header .content-center {
    top: 70% !important;
  }
  .section-work p {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1 !important;
  }
  .section-about-us h5 {
    font-size: 16px;
  }
  .team-player img {
    max-width: 170px !important;
    height: 150px;
    border-radius: 50%;
  }
  .bd-title {
    font-size: 32px !important;
  }

  /* video */
  .progMeta {
    font-size: 11px;
  }
  .progMeta .meta {
    margin-right: 6px;
  }
  .projModal,
  .packModal {
    max-height: 450px;
    min-height: 450px;
  }
}
/* Team members */

.team-members .card {
  height: 410px;
  padding: 10px;
}
.team-members .card img {
  width: 230px;
  height: 245px;
}
.team-members .card h4 {
  font-size: 1.3em;
  font-weight: 500;
}

/* Menu Dropdown item */
.dropdownItem {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  left: 100%;
  top: 1px;
}
.sub-sub-menu:hover .dropdown-content {
  display: block;
}
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.arrow-right {
  position: absolute !important;
  right: 0 !important;
  top: 14px !important;
}

@media screen and (max-width: 992px) {
  .dropdown-content {
    position: relative;
    display: block;
    min-width: 160px;
    z-index: 1;
    top: 1px;
    left: 20px;
    background: none;
    box-shadow: none;
  }
  .dropdown-content a {
    color: #a7a7a7 !important;
  }
}
